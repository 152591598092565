import { CustomCellRendererProps } from '@ag-grid-community/react'
import React from 'react'
import { useAppSelector } from '../../../store'
import { getListTradeOrders } from '../../../store/order/selectors'
import { SecurityStaticData } from '../../../store/securities/reducer'
import { TradingListContext } from '../Grid'
import { getDisplayStatus } from '../helpers'
import { useGetListTradingSecurity } from '../hooks/useGetListTradingSecurity'

const StatusRenderer = ({
  data,
  context
}: CustomCellRendererProps<SecurityStaticData, any, TradingListContext>) => {
  const security = useGetListTradingSecurity(data?.id)

  if (!security || !context.watchlistId) return <></>

  const getAggressOrders = useAppSelector(getListTradeOrders)
  const aggressOrders = getAggressOrders(
    security.id,
    security.isBid ? 'buy' : 'sell',
    context.watchlistId
  )

  const status = aggressOrders[0]?.status

  return <div>{getDisplayStatus(status)}</div>
}

export default StatusRenderer
