import { Action } from 'redux'
import { Order } from '../order/types'
import { State } from './reducer'
import {
  CREATELISTTRADINGLIST,
  CreateListTradingListAction,
  DeleteListTradingSecuritySuccessAction,
  DELETESECURITYFROMLIST,
  DELETESECURITYFROMLISTSUCCESS,
  ListTradingOrder,
  LISTTRADINGSECURITIES_FETCH,
  LISTTRADINGSECURITIES_FETCH_FAIL,
  LISTTRADINGSECURITIES_FETCH_SUCCESS,
  ListTradingSecuritiesFetchAction,
  ListTradingSecuritiesFetchSuccessAction,
  ListTradingSecurity,
  RESETLISTTRADINGTRANSACTIONID,
  ResetListTradingTransactionIdAction,
  SETLISTTRADINGTRANSACTIONID,
  SetListTradingTransactionIdAction,
  UPDATELISTTRADINGLIST,
  UPDATELISTTRADINGLISTSUCCESS,
  WorkingListTradingOrder
} from './types'

export const listTradingSecuritiesFetch = (
  listId: number
): ListTradingSecuritiesFetchAction => ({
  type: LISTTRADINGSECURITIES_FETCH,
  payload: listId
})

export interface ListTradingSecuritiesFetchFailAction extends Action {
  type: typeof LISTTRADINGSECURITIES_FETCH_FAIL
  payload: any
  error: true
}

export const listTradingSecuritiesFetchFail = (
  error: any
): ListTradingSecuritiesFetchFailAction => ({
  type: LISTTRADINGSECURITIES_FETCH_FAIL,
  payload: error,
  error: true
})

export const listTradingSecuritiesFetchSuccess = (
  securitiesList: ListTradingSecurity[]
): ListTradingSecuritiesFetchSuccessAction => ({
  type: LISTTRADINGSECURITIES_FETCH_SUCCESS,
  payload: securitiesList
})

export const createListTradingList = (
  name: string,
  orders: ListTradingOrder[]
): CreateListTradingListAction => ({
  type: CREATELISTTRADINGLIST,
  payload: { name, orders }
})

export const createListTradingListFailure = () =>
  ({
    type: 'listTrading.createListFailure'
  } as const)
export type CreateListTradingListFailureAction = ReturnType<
  typeof createListTradingListFailure
>

export const updateListTradingList = (
  listId: number,
  orders: WorkingListTradingOrder[]
) =>
  ({
    type: UPDATELISTTRADINGLIST,
    payload: { listId, orders }
  } as const)

export type UpdateListTradingListAction = ReturnType<
  typeof updateListTradingList
>

export const updateListTradingListSuccess = (
  orders: WorkingListTradingOrder[]
) =>
  ({
    type: UPDATELISTTRADINGLISTSUCCESS,
    payload: orders
  } as const)

export type UpdateListTradingListSuccessAction = ReturnType<
  typeof updateListTradingListSuccess
>

export const createWorkingListTradingOrder = (
  securityId: number,
  watchlistId: number,
  order: WorkingListTradingOrder
) =>
  ({
    type: `listTrading.createWorkingListTradingOrder`,
    payload: { ...order, securityId, watchlistId }
  } as const)
export type CreateWorkingListTradingOrderAction = ReturnType<
  typeof createWorkingListTradingOrder
>

export const setListTradingWatchlistId = (watchlistId: State['watchlistId']) =>
  ({
    type: `listTrading.setListTradingWatchlistId`,
    payload: watchlistId
  } as const)
export type SetListTradingWatchlistIdAction = ReturnType<
  typeof setListTradingWatchlistId
>

export interface DeleteListTradingSecurityAction extends Action {
  type: typeof DELETESECURITYFROMLIST
  payload: {
    listId: number
    securityId: number
  }
}

export const deleteSecurityFromList = (
  listId: number,
  securityId: number
): DeleteListTradingSecurityAction => ({
  type: DELETESECURITYFROMLIST,
  payload: { listId, securityId }
})

export const deleteSecurityFromListSuccess = (
  securityId: number
): DeleteListTradingSecuritySuccessAction => ({
  type: DELETESECURITYFROMLISTSUCCESS,
  payload: securityId
})

export const setListTradingTransactionId = (
  id: number
): SetListTradingTransactionIdAction => ({
  type: SETLISTTRADINGTRANSACTIONID,
  payload: id
})

export const resetListTradingTransactionId =
  (): ResetListTradingTransactionIdAction => ({
    type: RESETLISTTRADINGTRANSACTIONID
  })

export const selectListTradingSecurities = (
  securityIds: Array<ListTradingSecurity['id']>
) =>
  ({
    type: 'listTrading.selectListTradingSecurities',
    payload: securityIds
  } as const)
export type SelectListTradingSecuritiesAction = ReturnType<
  typeof selectListTradingSecurities
>

export const toggleSuborderSelection = (
  securityId: ListTradingSecurity['id'],
  orderId: Order['id']
) =>
  ({
    type: `listTrading.toggleSuborderSelection`,
    payload: { securityId, orderId }
  } as const)
export type ToggleSuborderSelectionAction = ReturnType<
  typeof toggleSuborderSelection
>

export const toggleCancelSubOrderSelection = (
  securityId: ListTradingSecurity['id'],
  orderId: Order['id']
) =>
  ({
    type: `listTrading.toggleCancelSubOrderSelection`,
    payload: { securityId, orderId }
  } as const)
export type ToggleCancelSubOrderSelectionAction = ReturnType<
  typeof toggleCancelSubOrderSelection
>

export const selectSecurityOrdersToCancel = (
  securityId: ListTradingSecurity['id'],
  orderIds: Array<Order['id']>
) =>
  ({
    type: `listTrading.selectSecurityOrdersToCancel`,
    payload: { securityId, orderIds }
  } as const)
export type SelectSecurityOrdersToCancelAction = ReturnType<
  typeof selectSecurityOrdersToCancel
>

export const deselectSecurityOrdersToCancel = (
  securityId: ListTradingSecurity['id']
) =>
  ({
    type: `listTrading.deselectSecurityOrdersToCancel`,
    payload: { securityId }
  } as const)
export type DeselectSecurityOrdersToCancelAction = ReturnType<
  typeof deselectSecurityOrdersToCancel
>

export const cancelListTradingOrders = (watchlistId: number) =>
  ({
    type: `listTrading.cancelListTradingOrders`,
    payload: { watchlistId }
  } as const)
export type CancelListTradingOrdersAction = ReturnType<
  typeof cancelListTradingOrders
>

export const tradeSelectedSecurities = (listId: number) =>
  ({
    type: `listTrading.tradeSelectedSecurities`,
    payload: listId
  } as const)
export type TradeSelectedSecuritiesAction = ReturnType<
  typeof tradeSelectedSecurities
>

export type ListTradingAction =
  | ListTradingSecuritiesFetchAction
  | ListTradingSecuritiesFetchSuccessAction
  | ListTradingSecuritiesFetchFailAction
  | CreateListTradingListAction
  | CreateListTradingListFailureAction
  | UpdateListTradingListAction
  | UpdateListTradingListSuccessAction
  | CreateWorkingListTradingOrderAction
  | SetListTradingWatchlistIdAction
  | DeleteListTradingSecurityAction
  | DeleteListTradingSecuritySuccessAction
  | SetListTradingTransactionIdAction
  | ResetListTradingTransactionIdAction
  | SelectListTradingSecuritiesAction
  | CancelListTradingOrdersAction
  | DeselectSecurityOrdersToCancelAction
  | SelectSecurityOrdersToCancelAction
  | ToggleSuborderSelectionAction
  | ToggleCancelSubOrderSelectionAction
  | TradeSelectedSecuritiesAction
