import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store'
import { createWorkingListTradingOrder } from '../../../store/listTrading/actions'
import { createWorkingOrderSelector } from '../../../store/listTrading/selectors'
import {
  ListTradingOrder,
  WorkingListTradingOrder,
  WorkingOrderFields
} from '../../../store/listTrading/types'

type Changes = Partial<WorkingListTradingOrder> & {
  isChanged: boolean
}

export const useWorkingListTradingOrder = (
  watchlistId: number,
  securityId?: number,
  cusipOrIsin?: string
) => {
  const [pendingChanges, setPendingChanges] = useState<Changes>({
    isChanged: false
  })
  const dispatch = useAppDispatch()
  const changesRef = useRef<Changes>(pendingChanges)
  changesRef.current = pendingChanges
  const getOrderData = useMemo(() => {
    return createWorkingOrderSelector()
  }, [])
  const storedOrder = useAppSelector((state) =>
    getOrderData(state, securityId ?? 0)
  )
  const order = useMemo(() => {
    return storedOrder
      ? storedOrder
      : ({ securityId, isTemp: true } as WorkingListTradingOrder)
  }, [securityId, storedOrder])

  const updateField = useCallback(
    <
      F extends WorkingOrderFields,
      V extends NonNullable<WorkingListTradingOrder[F]>
    >(
      fieldName: F,
      fieldValue: V
    ) => {
      if (!securityId) return
      setPendingChanges((changes) => ({
        ...changes,
        [fieldName]: fieldValue,
        isChanged: true
      }))
    },
    [securityId]
  )

  const updateFields = useCallback(
    (update: Partial<ListTradingOrder>) => {
      setPendingChanges((changes) => ({
        ...changes,
        ...update,
        isChanged: true
      }))
    },
    [order, watchlistId]
  )

  useEffect(() => {
    // on unmount when editing stops
    return () => {
      const updates = changesRef.current
      if (securityId && updates?.isChanged) {
        const { isChanged, ...changes } = updates
        const newOrder = {
          ...order,
          ...changes,
          securityId,
          cusipOrIsin
        }
        dispatch(
          createWorkingListTradingOrder(securityId, watchlistId, newOrder)
        )
      }
    }
  }, [])

  return {
    order,
    updateField,
    updateFields
  }
}
