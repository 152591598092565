import {
  ColDef,
  ColGroupDef,
  GetRowIdFunc,
  IRowNode,
  RowClassParams,
  SelectionChangedEvent
} from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import cx from 'classnames'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import TradeConfirm from '../../components/Activity/TradeConfirm/TradeConfirm'
import ListTradingDepthDetailRenderer from '../../components/DepthOfMarket/ListTradingDepthDetailRenderer.tsx'
import gridStyles from '../../components/Grid/grid.module.scss'
import { formatCoupon } from '../../helpers/formatting'
import { useAppDispatch, useAppSelector } from '../../store'
import {
  listTradingSecuritiesFetch,
  selectListTradingSecurities
} from '../../store/listTrading/actions'
import {
  getListTradingSecurities,
  getSelectedRows,
  getWorkingOrderBySecurityId
} from '../../store/listTrading/selectors'
import {
  ListTradingSecurity,
  WorkingOrderFields
} from '../../store/listTrading/types'
import {
  fetchListTradingSecurities,
  unsubscribeListTrading
} from '../../store/securities/actions'
import { SecurityStaticData } from '../../store/securities/reducer'
import { getListTradingGridPage } from '../../store/securities/selectors'
import { updateColumnsOrder } from '../../store/settings/actions'
import { getListTradingColumnOrder } from '../../store/settings/selectors'
import { getCurrentTheme } from '../../store/userPreferences/selectors.ts'
import { COUPON, ISIN, ISSUER, MATURITY } from '../BondList/columnDefs'
import BestPriceOrSpreadRenderer from './cells/BestPriceOrSpreadRenderer'
import BestSizeRenderer from './cells/BestSizeRenderer'
import BuySellToggleRenderer from './cells/BuySellToggleRenderer'
import DefaultWorkingOrderFieldRenderer from './cells/DefaultWorkingOrderFieldRenderer.tsx'
import ListTradingSecurityFieldRenderer from './cells/ListTradingSecurityFieldRenderer'
import PriceEditor from './cells/PriceEditor'
import PriceRenderer from './cells/PriceRenderer'
import SelectOrdersToCancelHeaderCheckbox from './cells/SelectOrdersToCancelHeaderCheckbox'
import SelectSecurityOrdersToCancelCheckbox from './cells/SelectSecurityOrdersToCancelCheckbox'
import SizeEditor from './cells/SizeEditor'
import SpreadEditor from './cells/SpreadEditor'
import SpreadRenderer from './cells/SpreadRenderer'
import StatusRenderer from './cells/StatusRenderer'
import WorkingOrderFieldRenderer from './cells/WorkingOrderFieldRenderer'

import styles from './grid.module.scss'
import { getBestTrade, isTradeable } from './helpers.ts'
import DepthOfMarketControls from './cells/DepthOfMarketControls.tsx'

interface Props {
  watchlistId: number
}

export type TradingListContext = {
  watchlistId: number
  showOrderDetails: (id: string | null) => void
}

const defaultColumn: ColDef = {
  // columnMenu: 'legacy', // coming in future version of ag grid
  editable: false,
  floatingFilter: true,
  lockPinned: true,
  menuTabs: ['columnsMenuTab'],
  resizable: false,
  suppressColumnsToolPanel: true,
  suppressPaste: true,
  minWidth: 10
}

const getRowId: GetRowIdFunc<SecurityStaticData> = ({ data }) => {
  return `${data?.id || ''}`
}

const invalidColId = 'no id provided'
const defaultColumns = ['Select', 'DepthTools']
const gridWidth = 1248
const applyColumnsOrder = (columns: ColDef[], userOrder: string[]) => {
  // flex doesn't work for pinned columns, so we need to calculate it
  let occupiedWidth = 0
  if (!userOrder.length) {
    return columns.map((col) => {
      const column = {
        ...col,
        suppressColumnsToolPanel: defaultColumns.includes(
          col.colId ?? invalidColId
        )
      }
      if (!col.pinned) {
        occupiedWidth += col.maxWidth ?? 10
      } else {
        column.width = gridWidth - occupiedWidth
      }
      return column
    })
  }
  const allColumns = [...defaultColumns, ...userOrder]
  const columnSort = (col1: ColDef, col2: ColDef) => {
    const pos1 = allColumns.indexOf(col1.colId ?? '')
    const pos2 = allColumns.indexOf(col2.colId ?? '')
    return pos1 - pos2
  }
  const mappedColumns = columns.map((col) => {
    const column = {
      ...col,
      hide: !allColumns.includes(col.colId ?? invalidColId),
      suppressColumnsToolPanel: defaultColumns.includes(
        col.colId ?? invalidColId
      )
    }
    if (!col.pinned) {
      occupiedWidth += column.hide ? 0 : column.maxWidth ?? 10
    } else {
      column.width = gridWidth - occupiedWidth
    }
    return column
  })
  const extraColumns = userOrder.includes('Status')
    ? []
    : [
        {
          colId: 'padding',
          headerName: '',
          hide: false,
          resizable: false,
          flex: 1,
          suppressMovable: true,
          suppressPaste: true,
          suppressHeaderMenuButton: true,
          suppressHeaderContextMenu: true
        }
      ]
  return [...mappedColumns.sort(columnSort), ...extraColumns]
}

const Grid = ({ watchlistId }: Props) => {
  const gridRef = useRef<AgGridReact<SecurityStaticData>>(null)
  const dispatch = useAppDispatch()
  const theme = useAppSelector(getCurrentTheme)
  const savedColumnOrder = useAppSelector(getListTradingColumnOrder)

  const getPageOfStaticSecurities = useAppSelector(getListTradingGridPage)
  const securityStaticData = getPageOfStaticSecurities(0)

  useEffect(() => {
    dispatch(listTradingSecuritiesFetch(watchlistId))
    dispatch(fetchListTradingSecurities(watchlistId, 0))

    return () => {
      // clears grid data and unsubscribes to this wl
      dispatch(unsubscribeListTrading())
    }
  }, [watchlistId])

  const [orderToShow, setOrderToShow] = useState<string | null>(null)
  const tradeConfirmId = Number((orderToShow || '').replace('O', ''))

  const context = useMemo<TradingListContext>(() => {
    return { watchlistId, showOrderDetails: setOrderToShow }
  }, [watchlistId])

  // ------------------ managing selections ------------------ //
  const listTradingSecurities = useAppSelector(getListTradingSecurities)
  const enableSelectAll =
    listTradingSecurities.filter((security) => isTradeable(security)).length > 0

  const securitiesWithSelectedOrders = useAppSelector(getSelectedRows)

  const isRowSelectable = useCallback(
    (row: IRowNode<SecurityStaticData>) => {
      const securityId = row.data?.id
      if (!securityId) return false
      const ltSecurity = listTradingSecurities.find(
        (security) => security.id === securityId
      )
      return !!ltSecurity && isTradeable(ltSecurity)
    },
    [listTradingSecurities]
  )

  const onSelectionChanged = useCallback(
    ({ api }: SelectionChangedEvent<SecurityStaticData>) => {
      const selectedSecurities = api
        .getSelectedRows()
        .map((security) => security.id)
      dispatch(selectListTradingSecurities(selectedSecurities))
    },
    [listTradingSecurities]
  )

  useEffect(() => {
    const api = gridRef.current?.api
    if (!api) return
    // update checkbox in ui to match redux state
    api.forEachNode((node) => {
      if (!isRowSelectable(node) && node.id) {
        node.setSelected(false)
      } else {
        if (node.data) {
          const selectedState = securitiesWithSelectedOrders.includes(
            node.data.id
          )
          if (node.isSelected() !== selectedState) {
            node.setSelected(selectedState)
          }
        }
      }
    })
  }, [listTradingSecurities, securitiesWithSelectedOrders])

  // ------------------ styling ------------------ //
  const hasSize = useCallback(
    (row: IRowNode<SecurityStaticData>) => {
      const securityId = row.data?.id
      if (!securityId) return false
      const ltSecurity = listTradingSecurities.find(
        (security) => security.id === securityId
      )
      return (
        !!ltSecurity &&
        (getBestTrade(ltSecurity)?.size ?? 0) > ltSecurity.remainingInterest
      )
    },
    [listTradingSecurities]
  )
  const rowClassRules = useMemo(() => {
    return {
      [styles['tradeable-row']]: ({
        node
      }: RowClassParams<SecurityStaticData>) => node && isRowSelectable(node),
      [styles.hasSize]: ({ node }: RowClassParams<SecurityStaticData>) =>
        node && hasSize(node)
    }
  }, [isRowSelectable])

  // ------------------ columns ------------------ //
  const workingOrderGetter = useAppSelector(getWorkingOrderBySecurityId)
  const colDefs: Array<ColDef | ColGroupDef> = useMemo(() => {
    const createWorkingOrderFieldComparator =
      (field: WorkingOrderFields, isSpreadCol: boolean = false) =>
      (
        _v1: any,
        _v2: any,
        node1: IRowNode<SecurityStaticData>,
        node2: IRowNode<SecurityStaticData>
      ) => {
        if (node1.data && !node2.data) {
          return 1
        }
        if (!node1.data && node2.data) {
          return -1
        }
        if (!node1.data || !node2.data) return 0
        const wo1 = workingOrderGetter(node1.data.id)
        const wo2 = workingOrderGetter(node2.data.id)
        let multiplier1 = 1
        let multiplier2 = 1
        if (field === 'price') {
          if (wo1.isSpread !== isSpreadCol) {
            multiplier1 = -999
          }
          if (wo2.isSpread !== isSpreadCol) {
            multiplier2 = -999
          }
        }
        return (
          (Number(wo1?.[field] ?? 0) * multiplier1 -
            Number(wo2?.[field] ?? 0)) *
          multiplier2
        )
      }
    const buySellComparator = createWorkingOrderFieldComparator('isBid')
    const priceComparator = createWorkingOrderFieldComparator('price')
    const spreadComparator = createWorkingOrderFieldComparator('price', true)

    const createLtSecurityFieldComparator =
      (fieldName: keyof ListTradingSecurity) =>
      (
        _v1: any,
        _v2: any,
        node1: IRowNode<SecurityStaticData>,
        node2: IRowNode<SecurityStaticData>
      ) => {
        if (node1.data && !node2.data) {
          return 1
        }
        if (!node1.data && node2.data) {
          return -1
        }
        if (!node1.data || !node2.data) return 0
        const lts1 = listTradingSecurities.find((s) => s.id === node1.data!.id)
        const lts2 = listTradingSecurities.find((s) => s.id === node2.data!.id)
        return Number(lts1?.[fieldName] ?? 0) - Number(lts2?.[fieldName] ?? 0)
      }

    return applyColumnsOrder(
      [
        {
          colId: 'Select',
          checkboxSelection: true,
          headerCheckboxSelection: enableSelectAll,
          headerCheckboxSelectionFilteredOnly: true,
          resizable: false,
          showDisabledCheckboxes: true,
          maxWidth: 22,
          suppressHeaderContextMenu: true,
          suppressHeaderMenuButton: !savedColumnOrder.length
        },
        {
          cellRenderer: DepthOfMarketControls,
          colId: 'DepthTools',
          headerName: '',
          hide: false,
          resizable: false,
          suppressAutoSize: true,
          suppressPaste: true,
          maxWidth: 22,
          suppressHeaderMenuButton: true,
          suppressHeaderContextMenu: true
        },
        {
          colId: ISSUER,
          field: ISSUER,
          filter: true,
          headerName: 'Ticker',
          maxWidth: 65,
          sortable: true
        },
        {
          cellClass: cx('number'),
          colId: COUPON,
          headerName: 'Coupon',
          field: 'coupon',
          filter: true,
          maxWidth: 70,
          sortable: true,
          valueFormatter: ({ value: coupon }) =>
            coupon ? `${formatCoupon(coupon)}` : ''
        },
        {
          colId: MATURITY,
          field: 'maturityDate',
          filter: true,
          headerName: 'Maturity',
          cellClass: cx('number'),
          maxWidth: 70,
          sortable: true,
          valueFormatter: ({ value: maturityDate }) =>
            maturityDate ? dayjs(maturityDate).format('MM/YY') : ''
        },
        {
          colId: ISIN,
          field: 'isin',
          filter: true,
          headerName: 'ISIN',
          resizable: true,
          maxWidth: 100,
          sortable: true
        },
        {
          cellClass: `editable ${styles.buySellCell}`,
          cellRenderer: WorkingOrderFieldRenderer(
            watchlistId,
            'isBid',
            BuySellToggleRenderer
          ),
          colId: 'Buy_Sell',
          comparator: buySellComparator,
          editable: false,
          headerName: 'Buy/Sell',
          maxWidth: 65,
          sortable: true
        },
        {
          cellClass: 'editable number',
          cellEditor: WorkingOrderFieldRenderer(
            watchlistId,
            'price',
            PriceEditor
          ),
          cellRenderer: WorkingOrderFieldRenderer(
            watchlistId,
            'price',
            PriceRenderer
          ),
          colId: 'TargetPrice',
          comparator: priceComparator,
          editable: true,
          headerName: 'Target Price',
          maxWidth: 90,
          singleClickEdit: true,
          sortable: true
        },
        {
          cellClass: 'editable number',
          cellRenderer: WorkingOrderFieldRenderer(
            watchlistId,
            'price',
            SpreadRenderer
          ),
          cellEditor: WorkingOrderFieldRenderer(
            watchlistId,
            'price',
            SpreadEditor
          ),
          colId: 'TargetSpread',
          comparator: spreadComparator,
          editable: true,
          headerName: 'Target Spread',
          maxWidth: 100,
          singleClickEdit: true,
          sortable: true
        },
        {
          cellClass: 'number',
          colId: 'Interest',
          editable: false,
          cellRenderer: ListTradingSecurityFieldRenderer(
            'interest' as const,
            DefaultWorkingOrderFieldRenderer
          ),
          comparator: createLtSecurityFieldComparator('interest'),
          headerName: 'Original Int',
          maxWidth: 90
        },
        {
          cellClass: 'editable number',
          colId: 'RemainingInterest',
          editable: true,
          headerName: 'Interest',
          cellEditor: WorkingOrderFieldRenderer(
            watchlistId,
            'size',
            SizeEditor
          ),
          cellRenderer: ListTradingSecurityFieldRenderer(
            'remainingInterest' as const,
            DefaultWorkingOrderFieldRenderer
          ),
          comparator: createLtSecurityFieldComparator('remainingInterest'),
          maxWidth: 65,
          singleClickEdit: true,
          sortable: true
        },
        {
          cellClass: 'number',
          colId: 'Completed',
          headerName: 'Completed',
          editable: false,
          cellRenderer: ListTradingSecurityFieldRenderer(
            'completedAmt' as const,
            DefaultWorkingOrderFieldRenderer
          ),
          comparator: createLtSecurityFieldComparator('completedAmt'),
          maxWidth: 85,
          sortable: true
        },
        {
          cellClass: 'number bidOfferCellText',
          cellRenderer: ListTradingSecurityFieldRenderer(
            undefined,
            BestPriceOrSpreadRenderer
          ),
          colId: 'BestTrade',
          headerName: 'Best Pric/Spr',
          maxWidth: 100
        },
        {
          cellClass: 'number bidOfferCellText size',
          cellRenderer: ListTradingSecurityFieldRenderer(
            undefined,
            BestSizeRenderer
          ),
          colId: 'BestSize',
          headerName: 'Best Size',
          maxWidth: 70
        },
        {
          cellClass: styles.selectCheckbox,
          cellRenderer: SelectSecurityOrdersToCancelCheckbox,
          colId: 'Cancel',
          headerComponent: SelectOrdersToCancelHeaderCheckbox,
          headerName: '',
          lockPinned: true,
          pinned: 'right',
          maxWidth: 30
        },
        {
          cellRenderer: StatusRenderer,
          cellClass: styles.statusColumn,
          colId: 'Status',
          editable: false,
          headerClass: styles.statusColumn,
          headerName: 'Status',
          lockPinned: true,
          pinned: 'right',
          width: 500
        }
      ],
      savedColumnOrder
    )
  }, [
    watchlistId,
    enableSelectAll,
    savedColumnOrder,
    workingOrderGetter,
    listTradingSecurities
  ])

  const handleColumnDefsChange = useCallback(
    ({ api }: { api: AgGridReact['api'] }) => {
      const columnsOrder = api
        .getAllDisplayedColumns()
        .map((col) => col.getColId())
        .filter((id) => id && !defaultColumns.includes(id))
      dispatch(updateColumnsOrder('listTradingSettings', columnsOrder))
    },
    []
  )

  return (
    <div
      className={cx(
        styles.listTrading,
        gridStyles.gridDimensions,
        theme,
        gridStyles.gridStyle
      )}
      data-testid="list-trading-grid"
    >
      <AgGridReact<SecurityStaticData>
        ref={gridRef}
        getRowId={getRowId}
        context={context}
        rowData={securityStaticData}
        // editing
        reactiveCustomComponents={true}
        stopEditingWhenCellsLoseFocus={true}
        //  selection
        suppressRowClickSelection={true}
        rowSelection="multiple"
        isRowSelectable={isRowSelectable}
        onSelectionChanged={onSelectionChanged}
        //  columns
        defaultColDef={defaultColumn}
        columnDefs={colDefs}
        maintainColumnOrder={true}
        onColumnMoved={handleColumnDefsChange}
        onColumnVisible={handleColumnDefsChange}
        suppressColumnVirtualisation={true}
        // master/detail
        embedFullWidthRows={true}
        masterDetail={true}
        detailCellRenderer={ListTradingDepthDetailRenderer}
        detailRowHeight={110}
        //  stopping things
        suppressDragLeaveHidesColumns={true}
        suppressRowTransform={true}
        suppressScrollOnNewData={true}
        //  visual
        groupHeaderHeight={0}
        headerHeight={20}
        rowHeight={20}
        overlayLoadingTemplate="Loading securities…"
        rowClassRules={rowClassRules}
      />
      {orderToShow && (
        <TradeConfirm
          tradeConfirmId={tradeConfirmId}
          handleTradeConfirmClick={setOrderToShow}
        />
      )}
    </div>
  )
}

export default Grid
