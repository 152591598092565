import { useMemo } from 'react'
import { useAppSelector } from '../../../store'
import { createListTradingSecuritySelector } from '../../../store/listTrading/selectors'

export const useGetListTradingSecurity = (securityId?: number) => {
  /*  There are going to be a bunch of these.
      By creating a selector fn per renderer, we allow
      it to be memoized and prevent unnecessary rerenders.
   */
  const getSecurityData = useMemo(() => {
    return createListTradingSecuritySelector()
  }, [])
  return useAppSelector((state) => getSecurityData(state, securityId))
}
