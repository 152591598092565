import cx from 'classnames'
import React, { useMemo } from 'react'
import styles from '../../../components/DepthOfMarket/Depth/DepthOfMarket.module.scss'
import { formatSpread } from '../../../components/DepthOfMarket/helpers'
import { formatCurrency, formatSize } from '../../../helpers/formatting'
import { useAppSelector } from '../../../store'
import { getMarketForSecurity } from '../../../store/depthOfMarket/selectors'
import { ListTradingSecurity } from '../../../store/listTrading/types'
import { getListTradeError } from '../../../store/order/selectors'
import { WatchList } from '../../../store/watchList/types'
import { getBestTrade, getDisplayStatus, isPending } from '../helpers'
import { useListTradingAggressOrders } from '../hooks/useListTradingAggressOrders'
import OrderPendingCountdown from './OrderPendingCountdown'
import SelectAggressOrderToCancelCheckbox from './SelectAggressOrderToCancelCheckbox'

import tableStyles from '../grid.module.scss'

interface Props {
  ltSecurity: ListTradingSecurity
  watchlistId: WatchList['id']
  showDetails: (orderId: string | null) => void
  currency?: string
}

const AggressOrdersTable = ({
  ltSecurity,
  watchlistId,
  showDetails,
  currency
}: Props) => {
  const {
    orders: aggressOrders,
    selectedOrderIds,
    toggleOrderCancel
  } = useListTradingAggressOrders(ltSecurity.id, ltSecurity.isBid, watchlistId)

  const getError = useAppSelector(getListTradeError)
  const depthOrders = useAppSelector(getMarketForSecurity)(
    0,
    ltSecurity.id,
    ltSecurity.isBid ? 'buy' : 'sell'
  )
  const bestTrade = getBestTrade(ltSecurity)

  const errRows = useMemo(() => {
    const error = bestTrade ? getError(bestTrade.id) : undefined
    const best = { ...bestTrade, error }
    const errors = depthOrders.map((order) => ({
      ...order,
      error: getError(order.id)
    }))
    if (best?.error) {
      // @ts-ignore
      errors.unshift(best)
    }
    return errors.filter((err) => err.error)
  }, [getError, depthOrders, bestTrade])

  return (
    <table
      className={cx(styles.ltStatus, tableStyles.aggressOrderTable)}
      data-testid={`listTrading-aggress-table-${ltSecurity.id}`}
    >
      <tbody>
        {aggressOrders.map((aggressOrder) => {
          const status = getDisplayStatus(aggressOrder?.status)
          const amount = aggressOrder.isSpreadOrder
            ? formatSpread(aggressOrder.spread)
            : formatCurrency(aggressOrder.price, currency)
          return (
            <tr
              key={aggressOrder.id}
              onDoubleClick={() => showDetails(aggressOrder.id)}
            >
              <td>
                <SelectAggressOrderToCancelCheckbox
                  onChange={toggleOrderCancel}
                  isChecked={selectedOrderIds.includes(aggressOrder.id)}
                  orderId={aggressOrder.id}
                  enabled={isPending(aggressOrder)}
                />
              </td>
              <td>
                {isPending(aggressOrder) && (
                  <OrderPendingCountdown order={aggressOrder} />
                )}
              </td>
              <td>
                {formatSize(
                  aggressOrder.size,
                  aggressOrder.totalSize,
                  false,
                  false
                )}
              </td>
              <td>{amount}</td>
              <td>{status}</td>
            </tr>
          )
        })}
        {errRows.map((row) => {
          const amount = row.isSpreadOrder
            ? formatSpread(row.spread)
            : formatCurrency(row.price, currency)
          return (
            <tr key={row.id}>
              <td></td>
              <td></td>
              <td>{formatSize(row.size, row.totalSize, false, false)}</td>
              <td>{amount}</td>
              <td title={row.error?.message}>Error: {row.error?.message}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default AggressOrdersTable
