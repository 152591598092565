import { createSelector } from 'reselect'
import { namespace } from '.'
import { getAllOrNoneStatus } from '../helpers'
import { getListTradeOrders } from '../order/selectors'
import { OrderType } from '../order/types'
import { WatchList } from '../watchList/types'
import { State } from './reducer'
import { ListTradingSecurity, WorkingListTradingOrder } from './types'

export const getState = (state: { [namespace]: State }) => state[namespace]

export const getListTradingSecurities = createSelector(
  [getState],
  (state) => state.securitiesList
)

export const getWorkingListTradingOrders = createSelector(
  [getState],
  (state) => state.workingSecurities
)

export const getListTradingError = createSelector(
  [getState],
  (state) => state.error
)

export const getListTradingPending = createSelector(
  [getState],
  (state) => state.pending
)

export const getListTradingTransactionId = createSelector(
  [getState],
  (state) => state.newWatchlistTransactionId
)
export const getListTradingWatchlistId = createSelector(
  [getState],
  (state) => state.watchlistId
)

export const getHasCreateError = createSelector([getState], (state) => {
  if (!state.createListState) return false
  return state.createListState.error
})

export const createListTradingSecuritySelector = () => {
  const actualSelector = createSelector(
    [getListTradingSecurities, (_state, securityId) => securityId],
    (securities, securityId) => {
      const security = securities.find((s) => s.id === securityId)
      if (!security) {
        return undefined
      }
      if (security.isSpread) return { ...security, targetPrice: undefined }
      return { ...security, targetSpread: undefined }
    }
  )
  return actualSelector
}

export const getWorkingOrders = createSelector(
  [getState],
  (state) => state.workingSecurities
)

export const getWorkingOrdersAsArray = createSelector(
  [getWorkingOrders],
  (workingSecurities) => {
    const result: WorkingListTradingOrder[] = []
    for (const securityId in workingSecurities) {
      if (workingSecurities[securityId]) {
        result.push(workingSecurities[securityId])
      }
    }
    return result
  }
)

export const getCountOfOrdersToCancel = createSelector(
  [getWorkingOrdersAsArray],
  (wos) => wos.flatMap((wo) => wo.ordersToCancel).length
)

/**
 * This one is for creating a selector per cell instance
 * that can stay stable as long as that particular
 * row does not change
 */
export const createWorkingOrderSelector = () => {
  return createSelector(
    [getWorkingOrders, (_state, securityId: number) => securityId],
    (workingOrders, securityId) => workingOrders[securityId]
  )
}

// This one is for selecting from epic
export const getWorkingOrderFromStateBySecurityId = createSelector(
  [getWorkingOrders, (_state, securityId: number) => securityId],
  (workingOrders, securityId) => workingOrders[securityId]
)

// use this one if you will be using the same selector for multiple securities
export const getWorkingOrderBySecurityId = createSelector(
  [getWorkingOrders],
  (workingOrders) => (securityId: number) => workingOrders[securityId]
)

export const getSelectedRows = createSelector([getState], (state) => {
  const result: number[] = []
  for (const securityId in state.workingSecurities) {
    if (state.workingSecurities[securityId]?.selectedOrders.length) {
      result.push(Number(securityId))
    }
  }
  return result
})
export const getSelectedRowCount = createSelector(
  [getSelectedRows],
  (selectedRows) => selectedRows.length
)

export const getSelectedTradeCount = createSelector(
  [getState, getSelectedRows],
  (state, rows) =>
    rows
      .flatMap(
        (securityId) =>
          state.workingSecurities[securityId]?.selectedOrders ?? []
      )
      .filter((orderId) => !!orderId).length
)

export const getSelectedSecurities = createSelector(
  [getListTradingSecurities, getSelectedRows],
  (securities, selectedRows) => {
    return securities.filter((security) => selectedRows.includes(security.id))
  }
)

export const getSelectedWorkingOrders = createSelector(
  [getSelectedRows, getWorkingOrders],
  (selectedRows, workingOrders) => {
    return selectedRows
      .map((securityId) => workingOrders[securityId])
      .filter((order) => !!order)
  }
)

export const getSecurityCancelCheckedStatus = createSelector(
  [getWorkingOrders, getListTradeOrders],
  (workingOrders, subOrderGetter) =>
    (
      securityId?: ListTradingSecurity['id'],
      watchlistId?: WatchList['id'],
      type?: OrderType
    ) => {
      if (!securityId || !watchlistId || !type) return 'hidden'
      return getAllOrNoneStatus(
        subOrderGetter(securityId, type, watchlistId),
        (order) => workingOrders[securityId]?.ordersToCancel.includes(order.id)
      )
    }
)

export const getHeaderCancelCheckedStatus = createSelector(
  [getListTradingSecurities, getSecurityCancelCheckedStatus],
  (ltSecurities, getSecurityCheckedStatus) => (watchlistId: WatchList['id']) =>
    getAllOrNoneStatus(
      ltSecurities,
      (security) =>
        getSecurityCheckedStatus(
          security.id,
          watchlistId,
          security.isBid ? 'sell' : 'buy'
        ) === 'all'
    )
)

export const getOrdersSelectedForCancel = createSelector(
  [getWorkingOrders],
  (workingOrders) => (securityId: ListTradingSecurity['id']) =>
    workingOrders[securityId]?.ordersToCancel ?? []
)
