import { Dispatch } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { combineEpics, createEpicMiddleware } from 'redux-observable'

import { namespace as authNamespace } from './auth'
import { AuthAction } from './auth/actions'
import authEpic from './auth/epic'
import authReducer from './auth/reducer'

import { namespace as activityNamespace } from './activity'
import { ActivityAction } from './activity/actions'
import activityReducer from './activity/reducer'

import { namespace as activityLogNamespace } from './activityLog'
import activityLogEpic from './activityLog/epic'
import activityLogReducer from './activityLog/reducer'

import { namespace as activityTradeBlotterNamespace } from './activityTradeBlotter'
import activityTradeBlotterEpic from './activityTradeBlotter/epic'
import activityTradeBlotterReducer from './activityTradeBlotter/reducer'
import { TradeBlotterActions } from './activityTradeBlotter/types'

import { namespace as activityTickerNamespace } from './admin/activityTicker'
import activityTickerEpic from './admin/activityTicker/epic'
import activityTickerReducer from './admin/activityTicker/reducer'
import { ActivityTickerAction } from './admin/activityTicker/types'

import { namespace as aggressAttemptNamespace } from './admin/aggressAttempt'
import aggressAttemptEpic from './admin/aggressAttempt/epic'
import aggressAttemptReducer from './admin/aggressAttempt/reducer'
import { AggressAttemptAction } from './admin/aggressAttempt/types'

import { namespace as systemParamNamespace } from './admin/sysparam'
import systemParamEpic from './admin/sysparam/epic'
import systemParamReducer from './admin/sysparam/reducer'
import { SystemParamAction } from './admin/sysparam/types'

import { namespace as restrictedTradesNamespace } from './admin/restrictedTrades'
import restrictedTradesEpic from './admin/restrictedTrades/epic'
import restrictedTradesReducer from './admin/restrictedTrades/reducer'
import { RestrictedTradesAction } from './admin/restrictedTrades/types'

import { namespace as tradeBlotterNamespace } from './admin/adminTradeBlotter'
import tradeBlotterEpic from './admin/adminTradeBlotter/epic'
import tradeBlotterReducer from './admin/adminTradeBlotter/reducer'
import { AdminTradeBlotterAction } from './admin/adminTradeBlotter/types'

import { namespace as bbmIsinNamespace } from './admin/bbmisin'
import bbmIsinEpic from './admin/bbmisin/epic'
import bbmIsinReducer from './admin/bbmisin/reducer'
import { BBMISINAction } from './admin/bbmisin/types'

import { namespace as issuerNamespace } from './depthOfMarket'
import { DepthOfMarketAction } from './depthOfMarket/actions'
import depthOfMarketEpic from './depthOfMarket/epic'
import depthOfMarketReducer from './depthOfMarket/reducer'
import { namespace as orderNamespace } from './order'
import orderEpic from './order/epic'
import orderReducer from './order/reducer'

import { namespace as securitiesNamespace } from './securities'
import { SecuritiesAction } from './securities/actions'
import securitiesEpic from './securities/epic'
import securitiesReducer from './securities/reducer'

import { namespace as alertFiltersNamespace } from './alertFilter'
import { AlertFilterAction } from './alertFilter/actions'
import alertFiltersEpic from './alertFilter/epic'
import alertFiltersReducer from './alertFilter/reducer'

import { namespace as windowsNamespace } from './windows'
import { WindowsAction } from './windows/actions'
import windowsEpic from './windows/epic'
import windowsReducer from './windows/reducer'

import { namespace as gridNamespace } from './grid'
import { GridAction } from './grid/actions'
import gridEpic from './grid/epic'
import gridReducer from './grid/reducer'

import { namespace as connectionNamespace } from './ws'
import { WsAction } from './ws/actions'
import wsEpic from './ws/epic'
import connectionReducer from './ws/reducer'

import { LastLookAction } from './lastLook/actions'
import lastLookEpic from './lastLook/epic'

import { namespace as watchListNamespace } from './watchList'
import { WatchListsAction } from './watchList/actions'
import watchListEpic from './watchList/epic'
import { reducer as watchListReducer } from './watchList/reducer'

import { namespace as stagedOrdersNamespace } from './stagedOrders'
import { StagedOrdersAction } from './stagedOrders/actions'
import stagedOrdersEpic from './stagedOrders/epic'
import stagedOrdersReducer from './stagedOrders/reducer'

import { namespace as checkedOrdersNamespace } from './checkedOrders'
import { CheckedOrderAction } from './checkedOrders/actions'
import checkedOrdersEpic from './checkedOrders/epic'
import checkedOrdersReducer from './checkedOrders/reducer'

import { namespace as searchSecuritiesNamespace } from './searchSecurities'
import { SearchSecuritiesActions } from './searchSecurities/actions'
import searchSecuritiesEpic from './searchSecurities/epic'
import searchSecuritiesReducer from './searchSecurities/reducer'

import { namespace as userPreferencesNamespace } from './userPreferences'
import { UserPreferencesAction } from './userPreferences/actions'
import userPreferencesEpic from './userPreferences/epic'
import { reducer as userPreferencesReducer } from './userPreferences/reducer'

import { composeReducers } from './helpers'
import { namespace as settingsNamespace } from './settings'
import { SettingsAction } from './settings/actions'
import settingsEpic from './settings/epic'
import { globalSettingsReducer, settingsReducer } from './settings/reducer'

import { namespace as booksNamespace } from './books'
import { BookAction } from './books/actions'
import booksEpic from './books/epic'
import booksReducer from './books/reducer'

import { namespace as alertsNamespace } from './alerts'
import { AlertsAction } from './alerts/actions'
import alertsEpic from './alerts/epic'
import alertsReducer from './alerts/reducer'

import { namespace as uploadNamespace } from './upload'
import { UploadOrdersActions } from './upload/actions'
import uploadOrdersEpic from './upload/epic'
import uploadReducer from './upload/reducer'

import { ActivityLogActions } from './activityLog/types'
import { namespace as marketNamespace } from './market'
import { MarketAction } from './market/actions'
import marketEpic from './market/epic'
import marketReducer from './market/reducer'

import { namespace as logNamespace } from './log'
import { LogAction } from './log/actions'
import logEpic from './log/epic'
import logReducer from './log/reducer'

import { HeartbeatAction } from './heartbeat/actions'
import heartbeatEpic from './heartbeat/epic'

import { namespace as webSettingsNamespace } from './webSettings'
import { WebSettingsAction } from './webSettings/actions'
import webSettingsEpic from './webSettings/epic'
import webSettingsReducer from './webSettings/reducer'

import { namespace as usersNamespace } from './users'
import usersEpic from './users/epic'
import usersReducer from './users/reducer'
import { UsersAction } from './users/types'

import { namespace as customersNamespace } from './customers'
import customersEpic from './customers/epic'
import customersReducer from './customers/reducer'
import { CustomersAction } from './customers/types'

import { namespace as tiersNamespace } from './admin/tiers'
import tiersEpic from './admin/tiers/epic'
import tiersReducer from './admin/tiers/reducer'
import { TierAction } from './admin/tiers/types'

import { namespace as historicalUserDataNamespace } from './admin/historicalUserData'
import historicalUserDataEpic from './admin/historicalUserData/epic'
import historicalUserDataReducer from './admin/historicalUserData/reducer'
import { HistoricalDataAction } from './admin/historicalUserData/types'

import { namespace as externalOrderStatsDataNamespace } from './admin/externalOrderStats'
import externalOrderStatsEpic from './admin/externalOrderStats/epic'
import externalOrderStatsReducer from './admin/externalOrderStats/reducer'
import { ExternalOrderStatsAction } from './admin/externalOrderStats/types'

import { namespace as queueStatsNamespace } from './admin/queueStats'
import queueStatsEpic from './admin/queueStats/epic'
import queueStatsReducer from './admin/queueStats/reducer'
import { QueueStatsAction } from './admin/queueStats/types'

import { namespace as managementReportsNamespace } from './admin/managementReports'
import managementReportsEpic from './admin/managementReports/epic'
import managementReportsReducer from './admin/managementReports/reducer'
import { ManagementReportAction } from './admin/managementReports/types'

import { namespace as userEditorDataNamespace } from './userEditorData'
import userEditorDataEpic from './userEditorData/epic'
import userEditorDataReducer from './userEditorData/reducer'
import { UserEditorDataAction } from './userEditorData/types'

import { namespace as queryNamespace } from './admin/query'
import { QueryAction } from './admin/query/actions'
import queryEpic from './admin/query/epic'
import queryReducer from './admin/query/reducer'

import { namespace as benchmarkDataNamespace } from './benchmarkData'
import benchmarkDataEpic from './benchmarkData/epic'
import benchmarkDataReducer from './benchmarkData/reducer'
import { BenchmarkDataAction } from './benchmarkData/types'

import { namespace as userDashboardNamespace } from './admin/userDashboard'
import { UserDashboardAction } from './admin/userDashboard/actions'
import userDashboardEpic from './admin/userDashboard/epic'
import userDashboardReducer from './admin/userDashboard/reducer'

import { namespace as passiveOrdersNamespace } from './passiveOrders'
import { PassiveOrdersAction } from './passiveOrders/actions'
import passiveOrdersEpic from './passiveOrders/epic'
import passiveOrdersReducer from './passiveOrders/reducer'

import { namespace as venueNamespace } from './admin/venues'
import { VenueAction } from './admin/venues/types'
import venueEpic from './admin/venues/epic'
import venueReducer from './admin/venues/reducer'

import { namespace as externalLiquidityStatsNamespace } from './admin/externalLiquidityStats'
import { ExternalLiquidityStatsAction } from './admin/externalLiquidityStats/types'
import externalLiquidityStatsEpic from './admin/externalLiquidityStats/epic'
import externalLiquidityStatsReducer from './admin/externalLiquidityStats/reducer'

import { namespace as listTradingNamespace } from './listTrading'
import { ListTradingAction } from './listTrading/actions'
import listTradingEpic from './listTrading/epic'
import { reducer as listTradingReducer } from './listTrading/reducer'

import { createReduxEnhancer } from '@sentry/react'

export type StoreAction =
  | ActivityAction
  | ActivityLogActions
  | AggressAttemptAction
  | BenchmarkDataAction
  | TradeBlotterActions
  | ActivityTickerAction
  | DepthOfMarketAction
  | GridAction
  | SecuritiesAction
  | WindowsAction
  | WsAction
  | AuthAction
  | LastLookAction
  | WatchListsAction
  | StagedOrdersAction
  | CheckedOrderAction
  | SearchSecuritiesActions
  | UserPreferencesAction
  | SettingsAction
  | BookAction
  | AlertsAction
  | UploadOrdersActions
  | AlertFilterAction
  | MarketAction
  | LogAction
  | HeartbeatAction
  | WebSettingsAction
  | UsersAction
  | CustomersAction
  | TierAction
  | HistoricalDataAction
  | ExternalOrderStatsAction
  | QueueStatsAction
  | SystemParamAction
  | BBMISINAction
  | ManagementReportAction
  | UserEditorDataAction
  | AdminTradeBlotterAction
  | QueryAction
  | UserDashboardAction
  | RestrictedTradesAction
  | PassiveOrdersAction
  | VenueAction
  | ExternalLiquidityStatsAction
  | ListTradingAction

const reducer = composeReducers(
  combineReducers({
    [authNamespace]: authReducer,
    [activityNamespace]: activityReducer,
    [activityLogNamespace]: activityLogReducer,
    [activityTradeBlotterNamespace]: activityTradeBlotterReducer,
    [aggressAttemptNamespace]: aggressAttemptReducer,
    [benchmarkDataNamespace]: benchmarkDataReducer,
    [issuerNamespace]: depthOfMarketReducer,
    [connectionNamespace]: connectionReducer,
    [gridNamespace]: gridReducer,
    [securitiesNamespace]: securitiesReducer,
    [alertFiltersNamespace]: alertFiltersReducer,
    [windowsNamespace]: windowsReducer,
    [orderNamespace]: orderReducer,
    [watchListNamespace]: watchListReducer,
    [stagedOrdersNamespace]: stagedOrdersReducer,
    [checkedOrdersNamespace]: checkedOrdersReducer,
    [searchSecuritiesNamespace]: searchSecuritiesReducer,
    [userPreferencesNamespace]: userPreferencesReducer,
    [settingsNamespace]: settingsReducer,
    [booksNamespace]: booksReducer,
    [alertsNamespace]: alertsReducer,
    [uploadNamespace]: uploadReducer,
    [marketNamespace]: marketReducer,
    [logNamespace]: logReducer,
    [webSettingsNamespace]: webSettingsReducer,
    [usersNamespace]: usersReducer,
    [customersNamespace]: customersReducer,
    [tiersNamespace]: tiersReducer,
    [historicalUserDataNamespace]: historicalUserDataReducer,
    [externalOrderStatsDataNamespace]: externalOrderStatsReducer,
    [queueStatsNamespace]: queueStatsReducer,
    [systemParamNamespace]: systemParamReducer,
    [restrictedTradesNamespace]: restrictedTradesReducer,
    [bbmIsinNamespace]: bbmIsinReducer,
    [managementReportsNamespace]: managementReportsReducer,
    [userEditorDataNamespace]: userEditorDataReducer,
    [tradeBlotterNamespace]: tradeBlotterReducer,
    [queryNamespace]: queryReducer,
    [activityTickerNamespace]: activityTickerReducer,
    [userDashboardNamespace]: userDashboardReducer,
    [passiveOrdersNamespace]: passiveOrdersReducer,
    [venueNamespace]: venueReducer,
    [externalLiquidityStatsNamespace]: externalLiquidityStatsReducer,
    [listTradingNamespace]: listTradingReducer
  }),
  globalSettingsReducer
)

export type State = ReturnType<typeof reducer>

const rootEpic = combineEpics(
  authEpic,
  activityLogEpic,
  activityTradeBlotterEpic,
  aggressAttemptEpic,
  benchmarkDataEpic,
  depthOfMarketEpic,
  securitiesEpic,
  windowsEpic,
  orderEpic,
  wsEpic,
  lastLookEpic,
  watchListEpic,
  checkedOrdersEpic,
  stagedOrdersEpic,
  searchSecuritiesEpic,
  userPreferencesEpic,
  settingsEpic,
  booksEpic,
  alertsEpic,
  uploadOrdersEpic,
  marketEpic,
  logEpic,
  heartbeatEpic,
  webSettingsEpic,
  usersEpic,
  customersEpic,
  tiersEpic,
  historicalUserDataEpic,
  externalOrderStatsEpic,
  queueStatsEpic,
  queryEpic,
  gridEpic,
  alertFiltersEpic,
  systemParamEpic,
  bbmIsinEpic,
  managementReportsEpic,
  userEditorDataEpic,
  tradeBlotterEpic,
  activityTickerEpic,
  userDashboardEpic,
  restrictedTradesEpic,
  passiveOrdersEpic,
  venueEpic,
  externalLiquidityStatsEpic,
  listTradingEpic
)

const epicMiddleware = createEpicMiddleware()
const sentryReduxEnhancer = createReduxEnhancer({})

const getStore = () => {
  const store = createStore<State, StoreAction, unknown, unknown>(
    reducer,
    composeWithDevTools(applyMiddleware(epicMiddleware), sentryReduxEnhancer)
  )

  epicMiddleware.run(rootEpic)

  return store
}

export const useAppSelector: TypedUseSelectorHook<State> = useSelector
export const useAppDispatch: () => Dispatch<StoreAction> = useDispatch

export default getStore
