import { CustomHeaderProps } from '@ag-grid-community/react'
import React, { useCallback } from 'react'
import Checkbox from '../../../components/Checkbox'
import { useAppDispatch, useAppSelector } from '../../../store'
import {
  deselectSecurityOrdersToCancel,
  selectSecurityOrdersToCancel
} from '../../../store/listTrading/actions'
import {
  getHeaderCancelCheckedStatus,
  getListTradingSecurities
} from '../../../store/listTrading/selectors'
import { getListTradeOrders } from '../../../store/order/selectors'
import { SecurityStaticData } from '../../../store/securities/reducer'
import { WatchList } from '../../../store/watchList/types'
import { TradingListContext } from '../Grid'
import { isPending } from '../helpers'

interface Props {
  watchlistId: WatchList['id']
  onChange: (selected: boolean) => void
}

const SelectOrdersToCancelHeaderCheckbox = ({
  watchlistId,
  onChange
}: Props) => {
  const status = useAppSelector(getHeaderCancelCheckedStatus)(watchlistId)
  return (
    <Checkbox
      locator="cancel-all-selected-listTrading-orders"
      onChange={() => onChange(status !== 'all')}
      checked={status === 'all'}
      indeterminate={status === 'some'}
    />
  )
}

const SelectOrdersToCancelHeaderCell = ({
  context: { watchlistId }
}: CustomHeaderProps<SecurityStaticData, TradingListContext>) => {
  const dispatch = useAppDispatch()
  const securities = useAppSelector(getListTradingSecurities)
  const getAggressOrders = useAppSelector(getListTradeOrders)

  const onChange = useCallback(
    (isSelected: boolean) => {
      securities.forEach((security) => {
        if (isSelected) {
          const type = security.isBid ? 'buy' : 'sell'
          const orders = getAggressOrders(
            security.id,
            type,
            watchlistId
          ).filter(isPending)
          const orderIds = orders.map((order) => order.id)
          dispatch(selectSecurityOrdersToCancel(security.id, orderIds))
        } else {
          dispatch(deselectSecurityOrdersToCancel(security.id))
        }
      })
    },
    [securities, dispatch]
  )

  return (
    <SelectOrdersToCancelHeaderCheckbox
      watchlistId={watchlistId}
      onChange={onChange}
    />
  )
}

export default SelectOrdersToCancelHeaderCell
