import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../store'
import { setListTradingWatchlistId } from '../../store/listTrading/actions'
import { getListTradingWatchlistId } from '../../store/listTrading/selectors'

import styles from './styles.module.scss'

const TradingListLayout = () => {
  const dispatch = useAppDispatch()
  const selectedWatchlistId = useAppSelector(getListTradingWatchlistId)
  const navigate = useNavigate()

  useEffect(() => {
    if (selectedWatchlistId === 'invalidWatchlist') {
      navigate('/')
    }
    return () => {
      dispatch(setListTradingWatchlistId(undefined))
    }
  }, [selectedWatchlistId])
  return (
    <div className={styles.listTradingUi}>
      {/* common ui elements here */}
      <Outlet />
    </div>
  )
}

export default TradingListLayout
