import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { EMPTY, from, of } from 'rxjs'
import { catchError, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators'
import { isPending } from '../../containers/TradingList/helpers'
import { getHub } from '../../helpers/hub'
import { getAllDOMOrders } from '../depthOfMarket/selectors'
import { addLogItem } from '../log/actions'
import {
  AddOrUpdateOperatorOrdersAction,
  cancelOrders,
  submitOrder,
  SubmitOrderAction
} from '../order/actions'
import { getListTradeOrders } from '../order/selectors'
import { Order } from '../order/types'
import { logError } from '../ws/actions'
import {
  CancelListTradingOrdersAction,
  createListTradingListFailure,
  CreateWorkingListTradingOrderAction,
  DeleteListTradingSecurityAction,
  deleteSecurityFromListSuccess,
  listTradingSecuritiesFetchFail,
  listTradingSecuritiesFetchSuccess,
  setListTradingTransactionId,
  toggleCancelSubOrderSelection,
  ToggleCancelSubOrderSelectionAction,
  TradeSelectedSecuritiesAction,
  updateListTradingList,
  UpdateListTradingListAction,
  updateListTradingListSuccess
} from './actions'
import { hasRequiredFields } from './helpers'
import {
  getSelectedSecurities,
  getWorkingOrderFromStateBySecurityId,
  getWorkingOrders
} from './selectors'
import {
  CREATELISTTRADINGLIST,
  CreateListTradingListAction,
  DELETESECURITYFROMLIST,
  LISTTRADINGSECURITIES_FETCH,
  LISTTRADINGSECURITIES_FETCH_FAIL,
  ListTradingSecuritiesFetchAction,
  ListTradingSecurity,
  UPDATELISTTRADINGLIST
} from './types'

const fetchListTradingSecuritiesEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType(LISTTRADINGSECURITIES_FETCH),
    switchMap((action: ListTradingSecuritiesFetchAction) => {
      const getListTradingSecurities$ = getHub().stream<ListTradingSecurity>(
        'GetListTradingSecurities',
        action.payload
      )
      return getListTradingSecurities$.pipe(
        map((securitiesList: ListTradingSecurity[]) => {
          return listTradingSecuritiesFetchSuccess(securitiesList)
        }),
        takeUntil(action$.pipe(ofType(LISTTRADINGSECURITIES_FETCH_FAIL))),
        catchError((err) =>
          of(listTradingSecuritiesFetchFail(err), logError(err))
        )
      )
    })
  )

const createListTradingListEpic: Epic = (action$, _state$) =>
  action$.pipe(
    ofType(CREATELISTTRADINGLIST),
    mergeMap((action: CreateListTradingListAction) => {
      return getHub()
        .invoke(
          'CreateListTradingList',
          action.payload.name,
          action.payload.orders
        )
        .pipe(
          mergeMap((id: number) => of(setListTradingTransactionId(id))),
          catchError((err) => of(createListTradingListFailure(), logError(err)))
        )
    })
  )

const saveOrderEpic: Epic = (action$) =>
  action$.pipe(
    ofType('listTrading.createWorkingListTradingOrder'),
    mergeMap((action: CreateWorkingListTradingOrderAction) => {
      if (!hasRequiredFields(action.payload)) return EMPTY
      const { watchlistId, ...order } = action.payload
      return of(updateListTradingList(watchlistId, [order]))
    })
  )

const updateListTradingListEpic: Epic = (action$, _state$) =>
  action$.pipe(
    ofType(UPDATELISTTRADINGLIST),
    mergeMap((action: UpdateListTradingListAction) => {
      const orders = action.payload.orders.map((originalOrder) => {
        const {
          securityId: _securityId,
          isTemp: _isTemp,
          selectedOrders: _selectedOrders,
          ordersToCancel: _ordersToCancel,
          ...order
        } = originalOrder
        return order
      })
      return getHub()
        .invoke('UpdateListTradingList', action.payload.listId, orders)
        .pipe(map(() => updateListTradingListSuccess(action.payload.orders)))
    })
  )

const deleteListTradingSecurityEpic: Epic = (action$, _state$) =>
  action$.pipe(
    ofType(DELETESECURITYFROMLIST),
    mergeMap((action: DeleteListTradingSecurityAction) => {
      return getHub()
        .invoke(
          'DeleteListTradingSecurity',
          action.payload.listId,
          action.payload.securityId
        )
        .pipe(
          map((securityId: number) => deleteSecurityFromListSuccess(securityId))
        )
    })
  )

const tradeSelectedSecurities: Epic = (action$, state$) =>
  action$.pipe(
    ofType('listTrading.tradeSelectedSecurities'),
    mergeMap((action: TradeSelectedSecuritiesAction) => {
      const selectedSecurities = getSelectedSecurities(state$.value)
      const actions: SubmitOrderAction[] = []
      const domOrders = getAllDOMOrders(state$.value).reduce((hash, order) => {
        return { ...hash, [order.id]: order }
      }, {} as Record<string, Order>)

      selectedSecurities.forEach((security) => {
        const workingOrder = getWorkingOrderFromStateBySecurityId(
          state$.value,
          security.id
        )

        let interest = security.remainingInterest

        workingOrder.selectedOrders.forEach((orderId) => {
          const trade = domOrders[orderId]
          if (!trade) return
          const tradeSize = Math.min(interest, interest || 9999, trade.size)
          if (!tradeSize) return
          interest -= tradeSize
          actions.push(
            submitOrder(trade, trade.id, tradeSize, -99, 0, '', action.payload)
          )
        })
      })

      if (!actions.length) return EMPTY
      return from([...actions])
    })
  )

const cancelListTradingOrders: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('listTrading.cancelListTradingOrders'),
    switchMap((action: CancelListTradingOrdersAction) => {
      const selectedOrders: Array<Order['id']> = []
      const workingOrders = getWorkingOrders(state$.value)
      const getAggressOrders = getListTradeOrders(state$.value)
      /* eslint-disable guard-for-in */
      for (const securityId in workingOrders) {
        const workingOrder = workingOrders[securityId]
        if (!workingOrder) {
          continue
        }
        if (workingOrder.ordersToCancel.length) {
          const aggressOrders = getAggressOrders(
            Number(securityId),
            workingOrder.isBid ? 'buy' : 'sell',
            action.payload.watchlistId
          ).filter(
            (order) =>
              isPending(order) && workingOrder.ordersToCancel.includes(order.id)
          )
          selectedOrders.push(...aggressOrders.map((o) => o.id))
        }
      }
      if (!selectedOrders.length) {
        return EMPTY
      }
      return of(
        cancelOrders(selectedOrders),
        addLogItem(
          `Cancelling ${selectedOrders.length} orders from list trading`
        )
      )
    })
  )

const deselectCancelledOrders: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('order.addOrUpdateOperatorOrders'),
    switchMap((action: AddOrUpdateOperatorOrdersAction) => {
      const workingOrders = getWorkingOrders(state$.value)
      const actions: ToggleCancelSubOrderSelectionAction[] = []
      action.payload
        .filter((order) => !isPending(order))
        .forEach((order) => {
          if (
            workingOrders[order.securityId]?.ordersToCancel.includes(order.id)
          ) {
            actions.push(
              toggleCancelSubOrderSelection(order.securityId, order.id)
            )
          }
        })
      return from(actions)
    })
  )

export default combineEpics(
  fetchListTradingSecuritiesEpic,
  createListTradingListEpic,
  saveOrderEpic,
  updateListTradingListEpic,
  deleteListTradingSecurityEpic,
  tradeSelectedSecurities,
  cancelListTradingOrders,
  deselectCancelledOrders
)
