import {
  CellClassParams,
  CellClickedEvent,
  CellValueChangedEvent,
  ColDef,
  GetRowIdFunc,
  IRowNode,
  PasteEndEvent,
  SelectionChangedEvent,
  ValueGetterParams,
  ValueParserFunc
} from '@ag-grid-community/core'
import { AgGridReact, CustomCellRendererProps } from '@ag-grid-community/react'
import { faCopy } from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import dayjs from 'dayjs'
import React, {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import ButtonWithFeedback from '../../components/ButtonWithFeedback/ButtonWithFeedback'
import ComponentHeader from '../../components/ComponentHeader/ComponentHeader.tsx'
import { finHeaderButtons } from '../../components/ComponentHeader/helpers.tsx'
import gridStyles from '../../components/Grid/grid.module.scss'
import NewWatchListInput from '../../components/Upload/NewWatchListInput'
import {
  createListTradingList,
  resetListTradingTransactionId,
  setListTradingWatchlistId
} from '../../store/listTrading/actions'
import {
  getHasCreateError,
  getListTradingTransactionId
} from '../../store/listTrading/selectors'
import { ListTradingOrder } from '../../store/listTrading/types'
import { getCurrentTheme } from '../../store/userPreferences/selectors.ts'
import { fetchWatchListsAction } from '../../store/watchList/actions'
import { getWatchList } from '../../store/watchList/selectors'
import { CUSIP } from '../BondList/columnDefs'

import { BuySellSwitch } from './cells/ToggleSwitch'
import styles from './grid.module.scss'
import tradingStyles from './styles.module.scss'

type GridType = Partial<ListTradingOrder> & { id: number }

const getRowId: GetRowIdFunc<GridType> = ({ data }) => {
  return `${data?.id || ''}`
}

const createEmptyRow = (id: number) => ({ id })
const createEmptyRows = (count: number, startId: number = 0) => {
  const blankArray = new Array(count).fill('')
  return blankArray.map((_emptyString, i) => createEmptyRow(i + startId))
}

const isBidParser: ValueParserFunc<GridType, GridType['isBid']> = ({
  newValue
}) => {
  const lcValue = newValue.toLowerCase()
  return ['b', 'buy', 'offer'].includes(lcValue)
}

const dateFormat = 'M/DD/YY, h:mm a'

const validationFields = ['cusipOrIsin', 'price', 'size'] as const
const getInvalidFields = (order: GridType) =>
  validationFields.filter((field) => order[field] === undefined)

const minRowCount = 10

const hasOrderData = (data: GridType) =>
  Object.values(data).filter((value) => value !== undefined).length > 1

const IsBidRenderer = ({ data }: CustomCellRendererProps<GridType>) => {
  if (!data || !hasOrderData(data)) return null
  return <BuySellSwitch securityId={data.id} isBid={!!data.isBid} />
}

const spreadToggleId = 'new-watchlist-is-spread'

const CreateTradingList = () => {
  const [rows, setRows] = useState<GridType[]>(createEmptyRows(minRowCount))
  const [selectedRows, setSelectedRows] = useState<GridType[]>([])
  const [watchlistName, setWatchlistName] = useState(dayjs().format(dateFormat))

  const dispatch = useDispatch()

  const theme = useSelector(getCurrentTheme)

  // ------------------ header controls ------------------ //
  const [isSpread, setIsSpread] = useState(true)
  const onIsSpreadChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    setIsSpread(checked)
  }, [])

  const rowsAreSelected =
    selectedRows.filter((row) => hasOrderData(row)).length > 0
  const disableSubmit = !rows.some(hasOrderData)
  const [finishDeletingRows, setFinishDeletingRows] = useState(false)
  const deleteSelectedRows = useCallback(() => {
    setFinishDeletingRows(true)
    setRows((oldRows) => oldRows.filter((row) => !selectedRows.includes(row)))
    setSelectedRows([])
  }, [selectedRows])
  useEffect(() => {
    if (finishDeletingRows) {
      gridRef.current?.api.setGridOption('rowData', rows)
      setFinishDeletingRows(false)
    }
  }, [finishDeletingRows, rows])
  const onSubmit = useCallback(() => {
    const hash: Record<number, string[]> = {}
    const dataRows = rows
      .filter(hasOrderData)
      .map((row) => ({ ...row, isSpread }))
    for (const row of dataRows) {
      const invalidFields = getInvalidFields(row)
      if (invalidFields?.length) {
        hash[row.id] = invalidFields
      }
    }
    setValidations(hash)
    if (Object.keys(hash).length) return
    // no error, create list now
    dispatch(
      createListTradingList(watchlistName, dataRows as ListTradingOrder[])
    )
  }, [rows, isSpread])

  // ------------------ validation ------------------ //
  const [validations, setValidations] = useState<Record<number, string[]>>({})

  const errorValueGetter = useCallback(
    ({ data: order }: ValueGetterParams<GridType>) => {
      if (!order) return undefined
      const errors = validations[order.id]
      const s = errors?.length > 1 ? 's' : ''
      if (errors) {
        return `Please correct ${errors.length} error${s}.`
      }
    },
    [validations]
  )
  const errorStyle = useCallback(
    ({ data: order, colDef }: CellClassParams<GridType>) => {
      if (!order) return false
      const errors = validations[order.id]
      return (errors && colDef.field && errors.includes(colDef.field)) ?? false
    },
    [validations]
  )

  const hasErrors = !!Object.keys(validations).length

  // ------------------ grid interaction ------------------ //
  const [selectAddedRows, setSelectAddedRows] = useState(false)
  const onPasteEnd = useCallback(({ api }: PasteEndEvent<GridType>) => {
    const newRows: GridType[] = []
    api.forEachNode((node) => {
      if (node.data?.cusipOrIsin) {
        newRows.push(node.data)
      }
    })
    const rowCount = newRows.length
    // if a user creates and then deletes a row, it leaves a gap in the ids
    // so we make sure we're not causing dupes
    const maxId = Math.max(...newRows.map((row) => row.id))
    if (rowCount) {
      setRows([
        ...newRows,
        ...createEmptyRows(Math.max(minRowCount - rowCount, 1), maxId + 1)
      ])
      setSelectAddedRows(true)
    }
    api.clearFocusedCell()
  }, [])

  useEffect(() => {
    const api = gridRef.current?.api
    if (api && selectAddedRows) {
      const toSelect: Array<IRowNode<GridType>> = []
      api.forEachNode((node) => {
        if (node.data && hasOrderData(node.data)) {
          toSelect.push(node)
        }
      })
      api.setNodesSelected({ nodes: toSelect, newValue: true })
      setSelectAddedRows(false)
    }
  }, [rows, selectAddedRows])

  const onCellValueChanged = useCallback(
    ({ data, colDef, newValue }: CellValueChangedEvent<GridType>) => {
      if (typeof colDef?.field !== 'string') return
      const fieldName = colDef.field
      setRows((oldRows) =>
        oldRows.map((row) =>
          row.id === data.id
            ? {
                ...row,
                [fieldName]: newValue
              }
            : row
        )
      )
    },
    []
  )

  const onSelectionChanged = useCallback(
    ({ api }: SelectionChangedEvent<GridType>) => {
      const newRows = api.getSelectedRows()
      setSelectedRows(newRows)
    },
    []
  )

  const toggleIsBid = useCallback(
    ({ data: order, event }: CellClickedEvent<GridType>) => {
      event?.preventDefault()
      event?.stopPropagation()
      if (!order) return
      setRows((oldRows) =>
        oldRows.map((row) =>
          row.id === order.id ? { ...row, isBid: !row.isBid } : row
        )
      )
    },
    []
  )

  // ------------------ grid definition ------------------ //
  const classes = cx(
    styles.listTrading,
    styles.create,
    gridStyles.gridDimensions,
    gridStyles.gridStyle,
    theme
  )
  const gridRef = useRef<AgGridReact<GridType>>(null)

  const defaultColumn = useMemo(() => {
    return {
      editable: true,
      lockPinned: true,
      minWidth: 10,
      menuTabs: [],
      resizable: false,
      singleClickEdit: true,
      suppressAutoSize: true,
      suppressColumnsToolPanel: true,
      width: 60,
      cellClassRules: {
        [styles.error]: errorStyle
      }
    }
  }, [errorStyle])

  const colDefs = useMemo(() => {
    return [
      {
        colId: 'select',
        editable: false,
        headerCheckboxSelection: true,
        headerName: '',
        checkboxSelection: ({ data }) => data && hasOrderData(data),
        suppressPaste: true,
        width: 22
      },
      {
        colId: CUSIP,
        field: 'cusipOrIsin',
        headerName: 'Cusip/Isin',
        singleClickEdit: false,
        flex: 1
      },
      {
        cellClass: styles.buySellCell,
        cellRenderer: IsBidRenderer,
        cellEditor: IsBidRenderer,
        colId: 'isBid',
        field: 'isBid',
        editable: true,
        headerName: 'Buy(B)/Sell(S)',
        onCellClicked: toggleIsBid,
        singleClickEdit: false,
        valueParser: isBidParser,
        width: 110
      },
      {
        cellDataType: 'number',
        field: 'size'
      },
      {
        cellClassRules: {
          [styles.error]: errorStyle
        },
        cellDataType: 'number',
        colId: 'price',
        field: 'price',
        headerName: `Target ${isSpread ? 'Spread' : 'Price'}`,
        width: 90
      },
      {
        cellClass: styles.errorMsg,
        colId: 'Errors',
        editable: false,
        flex: 3,
        hide: !hasErrors,
        valueGetter: errorValueGetter
      },
      {
        colId: 'empty',
        headerName: '',
        editable: false,
        flex: 3
      }
    ] as Array<ColDef<GridType>>
  }, [isSpread, hasErrors])

  const copyData = useCallback(() => {
    const headers = colDefs.reduce((arr, col) => {
      if (col.headerName) {
        arr.push(col.headerName)
      }
      return arr
    }, [] as string[])
    navigator.clipboard.writeText(headers.join('\t')).catch(console.warn)
  }, [colDefs])

  // ------------------ detecting the new wl and navigating ------------------ //
  const hasSaveError = useSelector(getHasCreateError)
  const watchlistTransactionId = useSelector(getListTradingTransactionId)
  const watchlists = useSelector(getWatchList)

  const navigate = useNavigate()

  useEffect(() => {
    /*
        When we create a WL, the BE doesn't immediately know the ID of the
        new WL. So we request the list of watchlists and monitor it to see if
        we can spot our new one and navigate to it.
     */
    dispatch(fetchWatchListsAction(-1))
    dispatch(setListTradingWatchlistId('new'))
    return () => {
      dispatch(resetListTradingTransactionId())
    }
  }, [])

  useEffect(() => {
    const newList = watchlists?.find(
      (wl) => wl.transactionId === watchlistTransactionId
    )
    if (newList?.id) {
      navigate(`./${newList.id}`, { replace: true })
    }
  }, [watchlistTransactionId, watchlists])

  return (
    <div className={cx(gridStyles.outerGridContainer, styles.listTrading)}>
      <header>
        <ComponentHeader
          title="Create New List"
          headerButtons={finHeaderButtons('ListTrading')}
        />
        <div className={tradingStyles.controls}>
          <NewWatchListInput
            className={tradingStyles.nameInput}
            watchlistName={watchlistName}
            setWatchlistName={setWatchlistName}
          />
          <div className={tradingStyles.spreadToggle}>
            <span>{isSpread ? <>Price</> : <strong>Price</strong>} </span>
            <span className="pretty p-switch p-smooth">
              <input
                type="checkbox"
                name={spreadToggleId}
                id={spreadToggleId}
                data-testid={spreadToggleId}
                onChange={onIsSpreadChange}
                checked={isSpread}
              />
              <div className="state p-default">
                <label htmlFor={spreadToggleId} id={`${spreadToggleId}-label`}>
                  {isSpread ? <strong>Spread</strong> : <>Spread</>}
                </label>
              </div>
            </span>
          </div>
          {rowsAreSelected ? (
            <button
              onClick={deleteSelectedRows}
              id="create-list-trading-delete-selected"
              data-testid="create-list-trading-delete-selected"
            >
              Remove Selected
            </button>
          ) : (
            <></>
          )}
          <ButtonWithFeedback
            contentUpdate="COPIED"
            onClick={copyData}
            timerToReturnToFirstState={3000}
            title="Copy Headers"
          >
            <FontAwesomeIcon icon={faCopy} />
          </ButtonWithFeedback>
          <button
            onClick={onSubmit}
            id="create-list-trading-submit"
            data-testid="create-list-trading-submit"
            disabled={disableSubmit}
          >
            Submit
          </button>
        </div>
      </header>
      {hasSaveError ? <div>Error saving watchlist or orders.</div> : <></>}
      <div className={classes} data-testid="create-list-trading-list-grid">
        <AgGridReact<GridType>
          ref={gridRef}
          groupHeaderHeight={0}
          headerHeight={20}
          rowHeight={20}
          columnDefs={colDefs}
          defaultColDef={defaultColumn}
          getRowId={getRowId}
          rowData={rows}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          stopEditingWhenCellsLoseFocus={true}
          enterNavigatesVertically={true}
          enterNavigatesVerticallyAfterEdit={true}
          onPasteEnd={onPasteEnd}
          onCellValueChanged={onCellValueChanged}
          onSelectionChanged={onSelectionChanged}
        />
      </div>
    </div>
  )
}

export default memo(CreateTradingList)
