import { CustomCellRendererProps } from '@ag-grid-community/react'
import React, { useCallback } from 'react'
import Checkbox from '../../../components/Checkbox'
import { useAppSelector } from '../../../store'
import { getSecurityCancelCheckedStatus } from '../../../store/listTrading/selectors'
import { ListTradingSecurity } from '../../../store/listTrading/types'
import { SecurityStaticData } from '../../../store/securities/reducer'
import { WatchList } from '../../../store/watchList/types'
import { TradingListContext } from '../Grid'
import { useGetListTradingSecurity } from '../hooks/useGetListTradingSecurity'
import { useListTradingAggressOrders } from '../hooks/useListTradingAggressOrders'

interface Props {
  security: ListTradingSecurity
  watchlistId: WatchList['id']
}

const SelectSecurityOrdersToCancelCheckbox = ({
  security,
  watchlistId
}: Props) => {
  const checkedStatusSelector = useAppSelector(getSecurityCancelCheckedStatus)
  const type = security.isBid ? 'buy' : 'sell'
  const status = checkedStatusSelector(security.id, watchlistId, type)
  const { selectOrDeselectAllSecurityOrders, hasPendingOrders } =
    useListTradingAggressOrders(security.id, security.isBid, watchlistId)
  const onChange = useCallback(() => {
    selectOrDeselectAllSecurityOrders(status !== 'all')
  }, [status])

  return (
    <Checkbox
      locator={`cancel-${security.id}-aggress-orders-check`}
      onChange={onChange}
      checked={status === 'all'}
      indeterminate={status === 'some'}
      disabled={!hasPendingOrders}
    />
  )
}

const SelectSecurityOrdersToCancelCell = ({
  data,
  context: { watchlistId }
}: CustomCellRendererProps<SecurityStaticData, never, TradingListContext>) => {
  const security = useGetListTradingSecurity(data?.id)
  if (security) {
    return (
      <SelectSecurityOrdersToCancelCheckbox
        security={security}
        watchlistId={watchlistId}
      />
    )
  }
}

export default SelectSecurityOrdersToCancelCell
