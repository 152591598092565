import { ListTradingSecurity } from '../../store/listTrading/types.ts'
import { Order, OrderStatus } from '../../store/order/types'

export const isGoodTrade = (
  myAmount: number,
  theirAmount: number | undefined,
  isBid: boolean,
  isSpread: boolean
) => {
  if (theirAmount === undefined) return false
  const bidMultiplier = isBid ? 1 : -1
  const spreadMultiplier = isSpread ? -1 : 1
  return (myAmount - theirAmount) * bidMultiplier * spreadMultiplier > 0
}

export const getBestTrade = (security: ListTradingSecurity) => {
  return security.isBid ? security.bestOfr : security.bestBid
}

export const isTradeable = (security: ListTradingSecurity) => {
  if (!security.remainingInterest || security.remainingInterest <= 0) {
    return false
  }
  const bestTrade = getBestTrade(security)
  if (!bestTrade) return false
  const myAmount = security.isSpread
    ? security.targetSpread
    : security.targetPrice
  if (myAmount === undefined) return false
  const theirAmount = security.isSpread ? bestTrade.spread : bestTrade.price
  return isGoodTrade(myAmount, theirAmount, security.isBid, security.isSpread)
}
export const isPending = ({ status }: Order) =>
  ['pending', 'waitingForConfirmation'].includes(status)
export const getDisplayStatus = (status?: OrderStatus) => {
  if (!status) return ''
  const capitalLetters = /[A-Z]/g
  const spaced = status.replace(capitalLetters, (replaced) => ' ' + replaced)
  if (spaced.length < 2) return spaced
  const [initial, ...rest] = spaced
  return initial.toUpperCase() + rest.join('')
}

export const toggleSwitch = 'pretty p-toggle p-switch p-smooth'
