import { Action } from 'redux'
import { Order } from '../order/types'

const NAMESPACE = `listTrading.`

//
// ACTIONS
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Fetch
 */
export const LISTTRADINGSECURITIES_FETCH = `${NAMESPACE}fetch`
export interface ListTradingSecuritiesFetchAction extends Action {
  type: typeof LISTTRADINGSECURITIES_FETCH
  payload: number
}

export const LISTTRADINGSECURITIES_FETCH_SUCCESS = `${NAMESPACE}fetchSuccess`
export interface ListTradingSecuritiesFetchSuccessAction extends Action {
  type: typeof LISTTRADINGSECURITIES_FETCH_SUCCESS
  payload: ListTradingSecurity[]
}

export const LISTTRADINGSECURITIES_FETCH_FAIL = `${NAMESPACE}fetchFail`

export const CREATELISTTRADINGLIST = `${NAMESPACE}createList`
export interface CreateListTradingListAction extends Action {
  type: typeof CREATELISTTRADINGLIST
  payload: { name: string; orders: ListTradingOrder[] }
}

export const UPDATELISTTRADINGLIST = `${NAMESPACE}updateList`

export const UPDATELISTTRADINGLISTSUCCESS = `${NAMESPACE}updateListSuccess`

export const DELETESECURITYFROMLIST = `${NAMESPACE}deleteSecurity`

export const DELETESECURITYFROMLISTSUCCESS = `${NAMESPACE}deleteSecuritySuccess`
export interface DeleteListTradingSecuritySuccessAction extends Action {
  type: typeof DELETESECURITYFROMLISTSUCCESS
  payload: number
}

export const SETLISTTRADINGTRANSACTIONID = `${NAMESPACE}setListTradingTransactionId`
export interface SetListTradingTransactionIdAction extends Action {
  type: typeof SETLISTTRADINGTRANSACTIONID
  payload: number
}

export const RESETLISTTRADINGTRANSACTIONID = `${NAMESPACE}resetListTradingTransactionId`
export interface ResetListTradingTransactionIdAction extends Action {
  type: typeof RESETLISTTRADINGTRANSACTIONID
}

export interface ListTradingOrder {
  cusipOrIsin: string
  isBid: boolean
  isSpread: boolean
  price: number
  size: number
}

export interface ListTradingSecurity {
  id: number
  securityDescription: string
  isin: string
  isSpread: boolean
  targetPrice: number | undefined
  targetSpread: number | undefined
  interest: number
  remainingInterest: number
  completedAmt: number
  isBid: boolean
  bestBid?: Order
  bestOfr?: Order
  transactionId?: number
}

export type WorkingListTradingOrder = Partial<ListTradingOrder> & {
  isTemp?: boolean
  securityId: ListTradingSecurity['id']
  selectedOrders: string[]
  ordersToCancel: string[]
}
export type WorkingOrderFields = keyof Omit<WorkingListTradingOrder, 'isTemp'>
