import React, { useCallback, useMemo } from 'react'
import { ListTradingSecurity } from '../../../store/listTrading/types.ts'
import Checkbox from '../../Checkbox'
import { OrderProps } from '../helpers.ts'

interface Props {
  checked: boolean
  enabled: boolean
  testId: string
  onToggle: () => void
}

const SelectOrder = ({ enabled, testId, onToggle, checked }: Props) => {
  return (
    <td>
      <Checkbox
        locator={testId}
        disabled={!enabled}
        onClick={onToggle}
        checked={checked}
      />
    </td>
  )
}

type Config = {
  security: ListTradingSecurity
  onOrderToggle: (orderId: string) => void
  checkedFn: (orderId: string) => boolean
  disabled: boolean
}

const configureSelectOrder =
  ({ security, onOrderToggle, checkedFn, disabled }: Config) =>
  ({ order }: OrderProps) => {
    const enabled = useMemo(() => {
      if (disabled) return false
      return order.canAggress && security.isBid
        ? order.type === 'sell'
        : order.type === 'buy'
    }, [order])
    const onToggle = useCallback(() => {
      onOrderToggle(order.id)
    }, [order])
    const testId = `select-order-${order.id}-lt`
    const checked = checkedFn(order.id)
    return (
      <SelectOrder
        enabled={enabled}
        onToggle={onToggle}
        testId={testId}
        checked={checked}
      />
    )
  }

export default configureSelectOrder
