import React from 'react'
import { formatSpread } from '../../../components/Activity/AlertDescription/helpers'
import { formatCurrency } from '../../../helpers/formatting'
import { getBestTrade } from '../helpers'
import { useGetListTradingSecurity } from '../hooks/useGetListTradingSecurity'
import { ListTradingSecurityRendererProps } from './ListTradingSecurityFieldRenderer'

const BestPriceOrSpreadRenderer = ({
  data
}: ListTradingSecurityRendererProps) => {
  const security = useGetListTradingSecurity(data?.id)
  if (!data || !security) return <></>
  const bestTrade = getBestTrade(security)

  if (!bestTrade) return <></>

  const cellStyle = security.isBid ? 'offer' : 'bid'
  const bestSpread = bestTrade.spread ? formatSpread(bestTrade.spread) : ''
  const bestPrice = formatCurrency(bestTrade.price, data.currency)

  const value = `${security.isSpread ? bestSpread : bestPrice}` || '-'

  return <span className={cellStyle}>{value}</span>
}

export default BestPriceOrSpreadRenderer
